import Vue from 'vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import auth from './auth'
import request from './request'
import store from "./store"
import vuexI18n from 'vuex-i18n';
import translationsNl from './locale/translationsNl.json'
import translationsFr from './locale/translationsFr.json'
import translationsEn from './locale/translationsEn.json'

import addButton from './components/shared/AddButton'
import addRemovePageElement from './components/shared/AddRemovePageElement'
import backButton from './components/shared/BackButton'
import cancelConfirmButtons from './components/shared/CancelConfirmButtons'
import login from './components/login/Login'
import languageSelector from './components/shared/LanguageSelector'
import pageElement from './components/shared/PageElement'
import pageNameLanguageSelector from './components/shared/PageNameLanguageSelector'
import preloader from './components/shared/Preloader'
import NotFoundPage from './components/page/NotFoundPage'

Vue.use(VueRouter)
Vue.use(vuexI18n.plugin, store)

Vue.i18n.add('Nl', translationsNl);
Vue.i18n.add('Fr', translationsFr);
Vue.i18n.add('En', translationsEn);

try {
  const currentLanguage = (navigator.language || navigator.userLanguage).split("-")[0]

  if (auth.userLanguage() != "") {
    Vue.i18n.set(auth.userLanguage())
  } else if (currentLanguage == "Fr") {
    Vue.i18n.set('Fr')
  }
  else if (currentLanguage == "En") {
    Vue.i18n.set('En')
  }
  else {
    Vue.i18n.set('Nl')
  }
}
catch (err) {
  Vue.i18n.set('Nl')
}

import App from './components/App'
import Login from './components/login/Login'
import RouteNotAuthorized from './components/RouteNotAuthorized'
import Dashboard from './components/Dashboard'
import ManageWebsites from './components/website/ManageWebsites'
import CreateEditWebsite from './components/website/CreateEditWebsite'
import DeleteWebsite from './components/website/DeleteWebsite'
import ManageUsers from './components/user/ManageUsers'
import DeleteUser from './components/user/DeleteUser'
import CreateEditUser from './components/user/CreateEditUser'
import EditPageDetails from './components/page/EditPageDetails'
import EditPage from './components/page/EditPage'
import EditPageItem from './components/page/EditPageItem'
import DeletePageItem from './components/page/DeletePageItem'
import ForgotPassword from './components/login/ForgotPassword'
import ResetPassword from './components/login/ResetPassword'
import ActivateAccount from './components/login/ActivateAccount'

request.get('/validatesession', {}, (res) => {
  if (res.success) {
    auth.setUser(res.data)
  } else {
    auth.deleteUser()
  }

  const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
      { path: '/' },
      { path: '/routenotauthorized', component: RouteNotAuthorized, name: 'routeNotAuthorized', meta: { adminRoute: false } },
      { path: '/dashboard', component: Dashboard, name: 'dashboard', meta: { adminRoute: false } },
      { path: '/websitesoverview', component: ManageWebsites, name: 'websites', meta: { adminRoute: true } },
      { path: '/login', component: Login, name: "login", meta: { adminRoute: false } },
      { path: '/websitesoverview/deletewebsite/:id', component: DeleteWebsite, name: 'deleteWebsite', meta: { adminRoute: true } },
      { path: '/websitesoverview/createeditwebsite', component: CreateEditWebsite, name: 'createNewWebsite', meta: { adminRoute: true } },
      { path: '/websitesoverview/createeditwebsite/:id', component: CreateEditWebsite, name: 'createEditWebsite', meta: { adminRoute: true } },
      { path: '/usersoverview', component: ManageUsers, name: 'manageUsers', meta: { adminRoute: true } },
      { path: '/usersoverview/deleteuser/:id', component: DeleteUser, name: 'deleteUser', meta: { adminRoute: true } },
      { path: '/usersoverview/createedituser', component: CreateEditUser, name: 'createNewUser', meta: { adminRoute: true } },
      { path: '/usersoverview/createedituser/:id', component: CreateEditUser, name: 'createEditUser', meta: { adminRoute: true } },
      { path: '/page/editpagedetails/:name/:websiteId/:id', component: EditPageDetails, name: 'editPageDetails', meta: { adminRoute: true } },
      { path: '/page/editpage/:id', component: EditPage, name: 'editPage', meta: { adminRoute: false } },
      { path: '/page/editpage/:id/addedititem/', component: EditPageItem, name: 'editPageAddItem', meta: { adminRoute: false } },
      { path: '/page/editpage/:id/addedititem/:itemId', component: EditPageItem, name: 'editPageAddEditItem', meta: { adminRoute: false } },
      { path: '/page/editpage/:id/deletepageitem/:itemId', component: DeletePageItem, name: 'deletePageItem', meta: { adminRoute: false } },
      { path: '/forgotpassword', component: ForgotPassword, name: 'forgotPassword', meta: { adminRoute: false } },
      { path: '/resetpassword/:hash', component: ResetPassword, name: 'resetPassword', meta: { adminRoute: false } },
      { path: '/activateaccount/:hash', component: ActivateAccount, name: 'activateAccount', meta: { adminRoute: false } },
      { path: '/pagenotfound', component: NotFoundPage, name: 'pagenotfound', meta: { adminRoute: false } },
      { path: '*', component: NotFoundPage, name: 'notFoundPage', meta: { adminRoute: false } }
    ]
  })

  router.beforeEach((to, from, next) => {
    //redirect user to login if user is not logged in
    if (!auth.loggedIn()
      && to.path !== '/login'
      && to.path !== '/forgotpassword'
      && to.path.indexOf("/resetpassword/") == -1
      && to.path.indexOf("/activateaccount/") == -1) {
      next('/login')
    }
    //redirect to dashboard if user is loggedIn
    else if (auth.loggedIn() && to.path == '/login') {
      next('/dashboard')
    }
    //notauthorized
    else if (to.meta.adminRoute && !auth.userIsAdministrator()) {
      next('/routenotauthorized')
    }
    //redirect user after login to "homepage" or dashboard
    else if (to.fullPath === "/") {
      request.get("/websites", null, (res) => {
        if (res.success) {
          (res.data[0].DashboardPageId.$oid !== "000000000000000000000000") ?
            next(`/page/editpage/${res.data[0].DashboardPageId.$oid}`) :
            next('/dashboard')
        } else {
          next('/dashboard')
        }
      })
    }
    //needs next to go to the next page if previus cases dont work
    else {
      next()
    }
  });

  Vue.component('addButton', addButton)
  Vue.component('addRemovePageElement', addRemovePageElement)
  Vue.component('backButton', backButton)
  Vue.component('cancelConfirmButtons', cancelConfirmButtons)
  Vue.component('languageSelector', languageSelector)
  Vue.component('pageElement', pageElement)
  Vue.component('pageNameLanguageSelector', pageNameLanguageSelector)
  Vue.component('preloader', preloader)
  Vue.component('login', login)

  new Vue({
    el: '#app',
    router,
    vuetify,
    render: h => h(App),
    store
  })
})

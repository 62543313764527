<template>
  <div>
    <backButton v-if="prevRoute != 'login'" />

    <v-layout row>
      <v-flex xs12>
        <template v-if="page">
          <!-- Add page title -->
          <v-layout
            v-if="
              !page.RepeatingContent ||
              (page.RepeatingContent &&
                (editMode || Object.keys(page.ContentDefinition).length == 0))
            "
            row
          >
            <v-flex sm9>
              <h2 class="mb-3">{{ page.Name[currentLanguage] }}</h2>
            </v-flex>
            <v-flex sm3 class="text-right">
              <template v-if="userIsAdministrator && !page.Custom">
                <v-switch
                  class="float-right mt-0"
                  v-model="editMode"
                  light
                  v-bind:label="$t('Edit mode')"
                ></v-switch>
              </template>
            </v-flex>
          </v-layout>

          <!-- The page has only one item -->
          <template v-if="!page.RepeatingContent && page.Custom">
            <component :is="customComponent"></component>
          </template>
          <template
            v-else-if="
              !page.RepeatingContent &&
              content &&
              Object.keys(content).length > 0
            "
          >
            <v-form ref="form" id="editPageForm" lazy-validation>
              <template class="mb-3" v-for="(item, index) in content[0].Data">
                <pageElement
                  :key="index"
                  :item="item"
                  :editMode="userIsAdministrator && editMode"
                  :websiteId="page.WebsiteId.$oid"
                  :pageId="page._id.$oid"
                  :objectName="index"
                  @removeItem="removeItem(index)"
                  ref="pageElement"
                />
              </template>
            </v-form>

            <template v-if="userIsAdministrator && editMode">
              <br />
              <v-card class="mb-4">
                <v-card-text>
                  <h3 class="mb-3">{{ $t("Add item") }}</h3>
                  <addRemovePageElement
                    @addItem="addObjectToPageContent"
                    :enableAdd="true"
                  />
                </v-card-text>
              </v-card>
            </template>
          </template>
          <!-- The page has repeating objects -->
          <template v-else-if="page.RepeatingContent">
            <!-- The page has no content definition -->
            <v-alert
              v-if="
                Object.keys(page.ContentDefinition).length == 0 && !editMode
              "
              type="info"
              :value="true"
              class="mb-3"
            >
              {{ $t("This page has not been configured yet") }}
            </v-alert>
            <!-- List repeating page items -->
            <template v-else-if="!editMode">
              <addButton
                :toRoute="{ name: 'editPageAddItem' }"
                :label="$t('Add item')"
              />
              <v-dialog v-model="exportDialog" scrollable max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="userIsAdministrator || page.AllowExportForAllUsers"
                    v-bind="attrs"
                    v-on="on"
                    class="mb-4 ml-0 add-button mr-3"
                    color="info"
                    dark
                  >
                    {{ $t("Export items") }}
                    <v-icon right>call_made</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>{{ $t("Export items") }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pt-5" style="height: 500px">
                    <p class="mb-3">
                      {{ $t("Select the items you wish to export.") }}
                    </p>
                    <v-form>
                      <template v-for="(item, index) in exportItems">
                        <v-checkbox
                          class="mt-0 font-weight-bold"
                          v-bind:key="'exportItems' + index"
                          v-model="item.selected"
                          :label="item.title"
                        ></v-checkbox>
                        <v-checkbox
                          class="mt-0 ml-5"
                          v-for="(
                            linkedItem, linkedItemIndex
                          ) in item.linkedExportItems"
                          v-bind:key="
                            'linkedExportItem' + linkedItemIndex + index
                          "
                          v-model="linkedItem.selected"
                          :label="linkedItem.title"
                        ></v-checkbox>
                      </template>
                    </v-form>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="exportDialog = false"
                    >
                      {{ $t("Cancel") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      v-on:click="exportPageContent()"
                    >
                      {{ $t("Export") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="bulkUpdateDialog" scrollable max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="
                      userIsAdministrator || page.AllowBulkUpdateForAllUsers
                    "
                    v-bind="attrs"
                    v-on="on"
                    class="mb-4 ml-0 add-button"
                    color="warning"
                    dark
                  >
                    {{ $t("Bulk update") }}
                    <v-icon right>edit</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>{{ $t("Bulk update") }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pt-5">
                    <v-form>
                      <h3>
                        {{ $t("Select the property you wish to update.") }}
                      </h3>

                      <v-autocomplete
                        :items="bulkUpdateItems"
                        v-model="selectedBulkUpdateProperty"
                        item-text="Title"
                        return-object
                        class="mb-3 mt-0"
                      ></v-autocomplete>

                      <h3 class="mb-3">
                        {{ $t("Enter or select the new value") }}
                      </h3>

                      <pageElement
                        v-if="selectedBulkUpdateProperty != null"
                        :item="selectedBulkUpdateProperty"
                        :websiteId="page.WebsiteId.$oid"
                        :pageId="page._id.$oid"
                        ref="pageElement"
                      />
                    </v-form>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="bulkUpdateDialog = false"
                    >
                      {{ $t("Cancel") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      v-on:click="bulkUpdatePageContent()"
                      :loading="bulkUpdatnigContent"
                    >
                      {{ $t("Bulk update") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <template v-if="userIsAdministrator && !page.Custom">
                <v-switch
                  class="float-right mt-0"
                  v-model="editMode"
                  light
                  v-bind:label="$t('Edit mode')"
                ></v-switch>
              </template>

              <v-card>
                <v-card-title>
                  <v-container class="pa-0">
                    <v-row>
                      <v-col class="pa-0 mb-3" cols="12" sm="10">
                        <h3 class="mt-3">{{ page.Name[currentLanguage] }}</h3>
                      </v-col>
                      <v-col class="pa-0 mb-3" cols="12" sm="2">
                        <v-text-field
                          class="pt-0"
                          v-if="page.AddSearchField"
                          :label="$t('Search')"
                          clearable
                          append-icon="search"
                          @click:clear="onSearchCleared()"
                          @keyup="searchContent"
                          v-model="searchValue"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="filters-row">
                      <v-col class="pa-0" cols="12">
                        <template
                          v-for="(item, index) in page.ContentDefinition"
                        >
                          <!-- filters -->
                          <template
                            v-if="
                              item.ShowFilter && page.Visualization == 'table'
                            "
                          >
                            <v-select
                              :items="getBooleanValues()"
                              v-if="item.Type == 'boolean'"
                              clearable
                              v-bind:key="index"
                              :label="item.Title"
                              item-value="value"
                              item-text="label"
                              v-on:change="filterChanged($event, index)"
                              class="property-filter"
                            ></v-select>
                            <v-select
                              :items="item.Options"
                              v-else-if="item.Type == 'dropdown'"
                              clearable
                              v-bind:key="index"
                              :label="item.Title"
                              item-value="Id"
                              item-text="Value"
                              v-on:change="filterChanged($event, index)"
                              class="property-filter"
                            ></v-select>
                            <v-menu
                              ref="dateFilterMenu"
                              v-bind:key="index"
                              v-else-if="item.Type == 'datepicker'"
                              :close-on-content-click="false"
                              v-model="dateFilterMenu"
                              transition="scale-transition"
                              offset-y
                              :nudge-right="40"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-on="on"
                                  :label="item.Title"
                                  prepend-icon="event"
                                  readonly
                                  validate-on-blur
                                  clearable
                                  @click:clear="filterChanged(undefined, index)"
                                  v-model="dateFilterValue"
                                  class="property-filter picker"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="dateFilterValue"
                                locale="nl-be"
                                first-day-of-week="1"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="dateFilterMenu = false"
                                  >{{ $t("Cancel") }}</v-btn
                                >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    filterChanged(dateFilterValue, index);
                                    dateFilterMenu = false;
                                  "
                                >
                                  {{ $t("Confirm") }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                            <v-menu
                              ref="timeFilterMenu"
                              v-bind:key="index"
                              v-else-if="item.Type == 'time'"
                              :close-on-content-click="false"
                              v-model="timeFilterMenu"
                              transition="scale-transition"
                              offset-y
                              :nudge-right="40"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-on="on"
                                  :label="item.Title"
                                  prepend-icon="event"
                                  readonly
                                  validate-on-blur
                                  clearable
                                  @click:clear="filterChanged(undefined, index)"
                                  v-model="timeFilterValue"
                                  class="property-filter picker"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-model="timeFilterValue"
                                locale="nl-be"
                                format="24hr"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="timeFilterMenu = false"
                                  >{{ $t("Cancel") }}</v-btn
                                >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    filterChanged(timeFilterValue, index);
                                    timeFilterMenu = false;
                                  "
                                >
                                  {{ $t("Confirm") }}
                                </v-btn>
                              </v-time-picker>
                            </v-menu>
                            <v-autocomplete
                              v-else-if="item.Type == 'content'"
                              :items="contentFilterSearchResults[index]"
                              :loading="searchingContentFilters[index]"
                              :search-input.sync="
                                searchContentFilterModels[index]
                              "
                              :multiple="item.MultipleItems"
                              :return-object="item.MultipleItems"
                              :chips="item.MultipleItems"
                              :deletable-chips="item.MultipleItems"
                              item-text="DisplayLabel"
                              item-value="_id"
                              clearable
                              v-bind:key="index"
                              class="property-filter"
                              :label="item.Title"
                              hide-no-data
                              @keyup="
                                searchContentFilter(item.LinkedPage, index)
                              "
                              v-on:change="filterChanged($event, index)"
                            >
                            </v-autocomplete>
                          </template>
                        </template>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-title>
                <div v-if="page.Visualization == 'rows' || !page.Visualization">
                  <v-layout
                    custom-overview-row
                    row
                    v-for="(item, index) in content"
                    :key="item.DisplayLabel"
                  >
                    <v-flex
                      v-if="page.ShowSortButtons"
                      lg2
                      sm3
                      xs3
                      pt-4
                      pl-3
                      pb-3
                      subheading
                    >
                      <v-btn
                        class="edit-page-action-button sort-button"
                        text
                        small
                        icon
                        color="grey"
                        v-on:click="moveUp(index)"
                      >
                        <v-icon>keyboard_arrow_up</v-icon>
                      </v-btn>

                      <v-btn
                        class="edit-page-action-button sort-button"
                        text
                        small
                        icon
                        color="grey"
                        v-on:click="moveDown(index)"
                      >
                        <v-icon>keyboard_arrow_down</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex
                      v-if="page.ShowSortButtons"
                      custom-overview-row-actions
                      class="lg8 sm6 xs4 text-xs-right"
                    >
                      <v-list-item-title
                        v-html="item.DisplayLabel"
                      ></v-list-item-title>
                    </v-flex>
                    <v-flex
                      v-else
                      custom-overview-row-actions
                      class="lg10 sm9 xs7 pl-3 text-xs-right"
                    >
                      <v-list-item-title
                        v-html="item.DisplayLabel"
                      ></v-list-item-title>
                    </v-flex>
                    <v-flex
                      custom-overview-row-actions
                      lg2
                      sm3
                      xs4
                      pt-3
                      pl-3
                      pb-3
                      subheading
                    >
                      <router-link
                        :to="{
                          name: 'editPageAddEditItem',
                          params: { id: page._id.$oid, itemId: item._id.$oid },
                        }"
                      >
                        <v-btn icon color="orange">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </router-link>

                      <router-link
                        :to="{
                          name: 'deletePageItem',
                          params: { id: page._id.$oid, itemId: item._id.$oid },
                        }"
                      >
                        <v-btn icon color="red">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </router-link>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else-if="page.Visualization == 'table'">
                  <v-data-table
                    :headers="tableHeaders"
                    :header-props="{ sortByText: $t('Sort by') }"
                    :items="tableRows"
                    :no-data-text="$t('No data available')"
                    :footer-props="{
                      itemsPerPageOptions: [-1],
                      pageText: '',
                      nextIcon: '',
                      prevIcon: '',
                    }"
                    :options.sync="options"
                    :custom-sort="customSort"
                  >
                    <template v-slot:[`item.sort`]="{ index }">
                      <v-btn
                        class="edit-page-action-button sort-button"
                        text
                        small
                        icon
                        color="grey"
                        v-on:click="moveUp(index)"
                      >
                        <v-icon>keyboard_arrow_up</v-icon>
                      </v-btn>

                      <v-btn
                        class="edit-page-action-button sort-button"
                        text
                        small
                        icon
                        color="grey"
                        v-on:click="moveDown(index)"
                      >
                        <v-icon>keyboard_arrow_down</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <router-link
                        :to="{
                          name: 'editPageAddEditItem',
                          params: { id: page._id.$oid, itemId: item._id.$oid },
                        }"
                      >
                        <v-btn icon color="orange">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </router-link>

                      <router-link
                        :to="{
                          name: 'deletePageItem',
                          params: { id: page._id.$oid, itemId: item._id.$oid },
                        }"
                      >
                        <v-btn icon color="red">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </router-link>
                    </template>
                  </v-data-table>
                </div>
              </v-card>

              <infinite-loading @infinite="getContent" ref="infiniteLoading">
                <div slot="spinner"><preloader></preloader></div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
            </template>

            <!-- Edit repeating objects content definition -->
            <template v-if="userIsAdministrator && editMode">
              <v-card class="mb-4">
                <v-card-text>
                  <h3 class="mb-3">{{ $t("Visualization") }}</h3>

                  <v-radio-group v-model="page.Visualization" mandatory>
                    <v-radio :label="$t('Rows')" value="rows"></v-radio>
                    <v-radio :label="$t('Table')" value="table"></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>

              <v-card class="mb-4">
                <v-card-text>
                  <h3 class="mb-3">{{ $t("Edit content definition") }}</h3>
                </v-card-text>

                <v-form ref="contentDefinitionform" lazy-validation>
                  <template v-for="(item, index) in page.ContentDefinition">
                    <v-layout
                      :key="index"
                      class="custom-overview-row mt-4"
                      v-bind:class="{ 'no-border': item.Type == 'dropdown' }"
                      row
                      wrap
                    >
                      <v-flex xs9 sm10>
                        <v-layout
                          class="custom-overview-row"
                          v-bind:class="{
                            'no-border': item.Type == 'dropdown',
                          }"
                          row
                          wrap
                        >
                          <v-flex custom-overview-row-title xs12 sm4>
                            <h4 class="mt-4">{{ index }}</h4>
                          </v-flex>

                          <v-flex custom-overview-row-title xs12 sm4>
                            <v-text-field
                              :label="$t('Object title')"
                              v-model="item.Title"
                              required
                              :rules="[
                                (v) => !!v || $t('Name is required'),
                                (v) =>
                                  v.length >= 2 ||
                                  $t('Minimum required characters is 2'),
                              ]"
                              validate-on-blur
                              class="ml-4"
                            ></v-text-field>

                            <template v-if="item.Type == 'content'">
                              <v-select
                                v-bind:items="getSharedPagesNotAssigned()"
                                single-line
                                v-model="item.LinkedPage"
                                item-value="_id"
                                :label="$t('Select a page')"
                                class="altSelect ml-4"
                              >
                                <template slot="selection" slot-scope="data">
                                  <div class="input-group__selections__comma">
                                    {{ data.item.Name[currentLanguage] }}
                                  </div>
                                </template>
                                <template slot="item" slot-scope="data">
                                  {{ data.item.Name[currentLanguage] }}
                                </template>
                              </v-select>
                            </template>
                            <template v-else-if="item.Type == 'croppedimage'">
                              <v-text-field
                                :label="$t('Width in pixels')"
                                v-model="item.Width"
                                class="float-left mr-3 ml-4"
                              ></v-text-field>

                              <v-text-field
                                :label="$t('Height in pixels')"
                                v-model="item.Height"
                              ></v-text-field>
                            </template>
                          </v-flex>

                          <v-flex custom-overview-row-title xs12 sm2>
                            <h4 class="mt-4 mb-4 item-type">{{ item.Type }}</h4>
                          </v-flex>

                          <v-flex custom-overview-row-title xs12 sm2>
                            <v-switch
                              v-if="
                                item.Type == 'text' ||
                                item.Type == 'richtext' ||
                                item.Type == 'image' ||
                                item.Type == 'croppedimage' ||
                                item.Type == 'gallery'
                              "
                              v-model="item.Translate"
                              class="mt-3 ml-2 mb-0"
                              light
                              v-bind:label="$t('Translate item')"
                            ></v-switch>

                            <v-switch
                              v-if="
                                item.Type == 'text' ||
                                item.Type == 'richtext' ||
                                item.Type == 'datepicker' ||
                                item.Type == 'time' ||
                                item.Type == 'dropdown' ||
                                item.Type == 'content' ||
                                item.Type == 'number'
                              "
                              v-model="item.Required"
                              class="mt-0 ml-2"
                              :label="$t('Field is required')"
                            >
                            </v-switch>

                            <v-switch
                              v-if="
                                item.Type == 'image' ||
                                item.Type == 'croppedimage' ||
                                item.Type == 'gallery'
                              "
                              v-model="item.AddAltTags"
                              class="mt-0 ml-2"
                              :label="$t('Add alt tags')"
                            >
                            </v-switch>

                            <v-switch
                              v-if="item.Type == 'content'"
                              class="mt-0 ml-2"
                              v-model="item.LoadItemsWhenCreated"
                              :label="$t('Load items when created')"
                            ></v-switch>

                            <v-switch
                              v-if="item.Type == 'content'"
                              class="mt-0 ml-2"
                              v-model="item.MultipleItems"
                              :label="$t('Multiple items')"
                            ></v-switch>

                            <v-switch
                              v-if="item.Type == 'time'"
                              class="mt-0 ml-2"
                              v-model="item.ShowSeconds"
                              :label="$t('Show seconds')"
                            ></v-switch>

                            <v-switch
                              class="mt-0 ml-2"
                              v-model="item.ReadOnly"
                              :label="$t('Read-only')"
                            ></v-switch>

                            <v-switch
                              v-if="
                                page.Visualization == 'table' &&
                                (item.Type == 'text' ||
                                  item.Type == 'richtext' ||
                                  item.Type == 'datepicker' ||
                                  item.Type == 'time' ||
                                  item.Type == 'dropdown' ||
                                  item.Type == 'boolean' ||
                                  item.Type == 'content' ||
                                  item.Type == 'number')
                              "
                              v-model="item.AddToTableView"
                              class="mt-0 ml-2"
                              :label="$t('Add to table view')"
                            >
                            </v-switch>

                            <v-switch
                              v-if="
                                item.Type == 'content' ||
                                item.Type == 'dropdown' ||
                                item.Type == 'boolean' ||
                                item.Type == 'datepicker' ||
                                item.Type == 'time'
                              "
                              v-model="item.ShowFilter"
                              class="mt-0 ml-2"
                              :label="$t('Show filter')"
                            >
                            </v-switch>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex custom-overview-row-actions xs3 sm2>
                        <v-btn
                          v-on:click="removeContentDefinitionItem(index)"
                          icon
                          color="red"
                          class="mr-3"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>

                    <template v-if="item.Type == 'dropdown'">
                      <v-layout
                        :key="index"
                        class="custom-overview-row"
                        row
                        wrap
                      >
                        <v-card-text>
                          <v-layout row wrap>
                            <v-flex xs12 sm10>
                              <v-layout row wrap>
                                <v-flex sm4 xs12>
                                  <b
                                    ><i
                                      >{{
                                        $t("Configure dropdown options")
                                      }}:</i
                                    ></b
                                  >
                                </v-flex>

                                <v-flex sm6 xs12>
                                  <template
                                    v-for="(
                                      option, optionIndex
                                    ) in item.Options"
                                  >
                                    <v-chip
                                      :key="optionIndex"
                                      close
                                      @input="removeOption(index, optionIndex)"
                                    >
                                      {{ option.Value }}
                                    </v-chip>
                                  </template>

                                  <v-layout row wrap>
                                    <v-flex sm6>
                                      <v-text-field
                                        :label="$t('Option name')"
                                        v-model="newOptionName"
                                        :error="addOptionFieldHasError"
                                        :rules="addOptionFieldRule"
                                        class="no-styling ml-1"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex sm6>
                                      <v-btn
                                        class="
                                          mt-3
                                          edit-page-action-button
                                          dropdown-action-button
                                        "
                                        color="green"
                                        dark
                                        v-on:click="addOption(index)"
                                      >
                                        {{ $t("Add option") }}
                                        <v-icon>add</v-icon>
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs12 sm2> </v-flex>
                          </v-layout>
                        </v-card-text>
                      </v-layout>
                    </template>
                  </template>
                </v-form>

                <v-card-text class="border-top">
                  <h3 class="mb-3 mt-5">
                    {{ $t("Add element to content definition") }}
                  </h3>

                  <addRemovePageElement
                    @addItem="addObjectToContentDefinition"
                    :enableAdd="true"
                  />
                </v-card-text>
              </v-card>

              <v-card class="mb-4">
                <v-card-text>
                  <h3 class="mb-3">{{ $t("Options") }}</h3>

                  <v-switch
                    v-model="page.AddSearchField"
                    light
                    v-bind:label="$t('Add search field')"
                  ></v-switch>

                  <v-switch
                    v-model="page.AllowExportForAllUsers"
                    light
                    v-bind:label="$t('Allow export for all users')"
                  ></v-switch>

                  <v-switch
                    v-model="page.ShowSortButtons"
                    light
                    v-bind:label="$t('Show sort buttons')"
                  ></v-switch>

                  <v-switch
                    v-model="page.AllowBulkUpdateForAllUsers"
                    light
                    v-bind:label="$t('Allow bulk update for all users')"
                  ></v-switch>
                </v-card-text>
              </v-card>
            </template>
          </template>

          <template v-if="page.RepeatingContent && editMode">
            <cancelConfirmButtons
              :confirmFunction="saveContentDefinition"
              :loading="savingContentDefinition"
              :confirmLabel="$t('Save page definition')"
            />
          </template>
          <template v-else-if="!page.RepeatingContent && !this.page.Custom">
            <cancelConfirmButtons
              :confirmFunction="saveContent"
              :loading="savingContent"
              :confirmLabel="$t('Save page')"
            />
          </template>
        </template>
        <template v-else-if="error.length == 0">
          <preloader></preloader>
        </template>

        <v-alert
          type="success"
          v-if="success.length > 0"
          class="mt-2"
          icon="check"
          :value="true"
        >
          {{ success }}
        </v-alert>
        <v-alert
          v-if="error.length > 0"
          class="mt-2"
          color="error"
          icon="warning"
          :value="true"
        >
          {{ error }}
        </v-alert>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import request from "../../request";
import helpers from "../../helpers";
import config from "../../config";
import auth from "../../auth";
import Vue from "vue";

import { mapGetters } from "vuex";

import InfiniteLoading from "vue-infinite-loading";
export default {
  data() {
    return {
      error: "",
      success: "",
      userToken:
        localStorage.user == null ? "" : JSON.parse(localStorage.user).Token,
      page: null,
      linkedPages: null,
      content: null,
      userIsAdministrator: auth.userIsAdministrator(),
      editMode: false,
      froalaOptions: {
        toolbar: [
          "bold",
          "italic",
          "underline",
          "|",
          "align",
          "formatOL",
          "formatUL",
        ],
        toolbarMD: [
          "bold",
          "italic",
          "underline",
          "|",
          "align",
          "formatOL",
          "formatUL",
        ],
      },
      file: null,
      objectTypes: config.objectTypes,
      newOptionName: "",
      savingContent: false,
      savingContentDefinition: false,
      addOptionFieldHasError: false,
      addOptionFieldRule: [],
      options: {},
      tableSortBy: "",
      tableSortDescending: false,
      contentIsLoading: false,
      searchValue: "",
      searchTimeout: null,
      dateFilterMenu: false,
      dateFilterValue: null,
      timeFilterMenu: false,
      timeFilterValue: null,
      contentFilterSearchResults: [],
      searchingContentFilters: [],
      searchContentFilterModels: [],
      pageFilters: [],
      exportDialog: false,
      selectedBulkUpdateProperty: null,
      bulkUpdateDialog: false,
      bulkUpdatnigContent: false,
      prevUrl: null,
      prevRoute: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.name;
    });
  },
  created() {
    this.getPage();
  },

  watch: {
    "$route.params.id": function () {
      this.getPage();
    },
    options: {
      handler() {
        if (this.content) {
          this.content = null;
          this.$refs.infiniteLoading.stateChanger.reset();
          this.getContent();
        }
      },
    },
    deep: true,
  },
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
    }),
    currentLanguage: {
      get: function () {
        return Vue.i18n.locale();
      },
    },
    customComponent() {
      return () =>
        this.page && this.page.Custom
          ? import("@/components/custom/" + this.page.UniqueName + ".vue")
          : null;
    },
    tableHeaders() {
      if (this.page.Visualization == "table" && this.page.ContentDefinition) {
        var headers = [];

        if (this.page.ShowSortButtons) {
          headers.push({
            text: Vue.i18n.translate("Sort"),
            value: "sort",
            sortable: false,
          });
        }

        for (var value in this.page.ContentDefinition) {
          if (this.page.ContentDefinition[value].AddToTableView) {
            headers.push({
              text: this.page.ContentDefinition[value].Title,
              value: value,
            });
          }
        }

        headers.push({
          text: Vue.i18n.translate("Actions"),
          value: "actions",
          sortable: false,
          align: "right",
        });
        return headers;
      } else {
        return [];
      }
    },
    tableRows() {
      if (this.page.Visualization == "table" && this.content) {
        var rows = [];

        for (var value in this.content) {
          var row = {};
          row["_id"] = this.content[value]._id;

          for (var dataValue in this.content[value].Data) {
            var dataValueLanguage = !this.content[value].Data[dataValue]
              .Translate
              ? "Nl"
              : this.currentLanguage;

            if (this.content[value].Data[dataValue].Type == "boolean") {
              row[dataValue] = this.content[value].Data[dataValue].Value
                ? Vue.i18n.translate("Yes")
                : Vue.i18n.translate("No");
            } else if (this.content[value].Data[dataValue].Type == "content") {
              row[dataValue] = this.content[value].Data[dataValue].ValueSort;
            } else if (this.content[value].Data[dataValue].Type == "dropdown") {
              var result = this.content[value].Data[dataValue].Options.filter(
                (obj) => {
                  return obj.Id == this.content[value].Data[dataValue].Value;
                }
              );

              row[dataValue] = result[0].Value;
            } else if (
              this.content[value].Data[dataValue].Value[dataValueLanguage] ==
              undefined
            ) {
              row[dataValue] = this.content[value].Data[dataValue].Value;
            } else {
              var text = this.content[value].Data[dataValue].Value[
                dataValueLanguage
              ].replace(/<[^>]*>?/gm, "");

              if (text.length > 80) {
                row[dataValue] = text.substr(0, 80) + "...";
              } else {
                row[dataValue] = text;
              }
            }
          }

          rows.push(row);
        }

        return rows;
      } else {
        return [];
      }
    },
    exportItems() {
      var exportItems = this.getExportItemsFromPage(this.page);
      if (this.linkedPages) {
        for (var value in this.page.ContentDefinition) {
          if (this.page.ContentDefinition[value].LinkedPage) {
            var linkedPage = this.linkedPages.filter((obj) => {
              return (
                obj._id.$oid ==
                this.page.ContentDefinition[value].LinkedPage.$oid
              );
            })[0];
            if (linkedPage) {
              var index = exportItems.findIndex((obj) => obj.value == value);
              exportItems[index].linkedExportItems =
                this.getExportItemsFromPage(linkedPage, true);
            }
          }
        }
      }
      return exportItems;
    },
    bulkUpdateItems() {
      if (this.page.RepeatingContent) {
        var items = [];
        for (var value in this.page.ContentDefinition) {
          if (
            this.page.ContentDefinition[value].Type != "image" &&
            this.page.ContentDefinition[value].Type != "croppedimage" &&
            this.page.ContentDefinition[value].Type != "gallery" &&
            this.page.ContentDefinition[value].Type != "files"
          ) {
            items.push(this.page.ContentDefinition[value]);
          }
        }
        return items;
      }
      return [];
    },
  },
  components: {
    InfiniteLoading,
  },
  methods: {
    saveContentDefinition() {
      if (this.$refs.contentDefinitionform.validate()) {
        //used when saving the content definition
        this.savingContentDefinition = true;

        request.put("/page", this.page, (res) => {
          this.savingContentDefinition = false;

          if (res.success) {
            this.editMode = false;
          } else {
            this.error = res.message;
          }
        });
      }
    },
    saveContent() {
      //used when saving a page with non-repeating objects
      this.error = "";

      var formCustomElementsValid =
        helpers.validateFormPageElements("editPageForm");
      var formIsValid = this.$refs.form.validate();

      if (formCustomElementsValid && formIsValid) {
        this.savingContent = true;
        this.content[0].DisplayLabel = helpers.getDisplayLabel(
          this.content[0].Data
        );
        request.put("/content", this.content[0], (res) => {
          this.savingContent = false;

          if (res.success) {
            if (
              this.page.SynchronizationPutUrl &&
              this.page.SynchronizationPutUrl.length > 0
            ) {
              request.put(
                this.page.SynchronizationPutUrl,
                this.content[0],
                (res) => {
                  if (!res.success) {
                    this.error = res.message;
                  }
                }
              );
            }
            this.success = Vue.i18n.translate(
              "The page was successfully saved"
            );
            setTimeout(() => {
              this.success = "";
            }, config.hideMessageTimeout);
          }
        });
      } else {
        this.error = Vue.i18n.translate(
          "Please make sure all text fields have a value"
        );
      }
    },
    getPage() {
      this.page == null;

      request.get("/page/" + this.$route.params.id, null, (res) => {
        if (res.success) {
          this.page = res.data;
          this.$store.dispatch("setSelectedWebsiteById", this.page.WebsiteId);

          if (!this.page.Custom) {
            if (!this.page.RepeatingContent) {
              this.getPageContent();
            } else {
              if (this.page.ContentDefinition == null) {
                this.page.ContentDefinition = {};
              }
            }
          }

          //Get content filter data
          for (var value in this.page.ContentDefinition) {
            if (
              this.page.ContentDefinition[value].Type == "content" &&
              this.page.ContentDefinition[value].LoadItemsWhenCreated
            ) {
              this.searchingContentFilters[value] = false;
              this.searchContentFilterModels[value] = "";
              this.searchContentFilter(
                this.page.ContentDefinition[value].LinkedPage,
                value
              );
            }
          }

          //Get linked pages
          if (this.page.RepeatingContent) {
            request.get(
              "/linkedpages/" + this.$route.params.id,
              null,
              (res) => {
                if (res.success) {
                  this.linkedPages = res.data;
                } else {
                  this.error = res.message;
                }
              }
            );
          }
        } else {
          this.error = res.message;
          this.$router.push({ name: "pagenotfound" });
          this.$router.go(1);
        }
      });
    },
    getPageContent() {
      this.content == null;

      //Content = array of content objects
      //This function is preferably used on pages with non-repeating objects
      request.get("/pagecontent/" + this.$route.params.id, null, (res) => {
        if (res.success) {
          this.content = res.data;

          //Non-repeating pages have only one content object
          if (this.content[0].Data == null) {
            this.content[0].Data = {};
          }

          if (this.content[0].DisplayLabel == null) {
            this.content[0].DisplayLabel = "";
          }
        } else {
          this.error = res.message;
        }
      });
    },
    getContent($state) {
      if (!this.contentIsLoading) {
        this.contentIsLoading = true;

        if (this.options.sortBy) {
          if (this.options.sortBy.length > 0) {
            this.tableSortBy = this.options.sortBy[0];
          }

          if (this.options.sortDesc.length > 0) {
            this.tableSortDescending = this.options.sortDesc[0];
          } else if (
            this.options.sortBy.length == 0 &&
            this.tableSortBy != ""
          ) {
            this.tableSortDescending = !this.tableSortDescending;
          }
        }

        var urlParameters =
          this.$route.params.id +
          "?start=" +
          (this.content == null ? 0 : this.content.length) +
          "&sortBy=" +
          this.tableSortBy +
          "&sortDescending=" +
          this.tableSortDescending +
          "&search=" +
          (this.searchValue == null ? "" : this.searchValue) +
          this.getPageFiltersQueryString();

        var getContentUrl =
          "/" +
          (this.page.Visualization == "table" ? "" : "minified") +
          "pagecontent/" +
          urlParameters;
        var getContentCountUrl = "/pagecontentcount/" + urlParameters;

        request.getAll([getContentCountUrl, getContentUrl], null, (res) => {
          if (res[0].success) {
            this.page.ContentCount = res[0].data;

            if (res[1].success) {
              if (this.content == null) {
                this.content = res[1].data;
              } else if (this.content.length < this.page.ContentCount) {
                this.content = this.content.concat(res[1].data);
              }

              this.$refs.infiniteLoading.stateChanger.loaded();

              if (this.content.length == this.page.ContentCount && $state) {
                this.$refs.infiniteLoading.stateChanger.complete();
              }

              if (this.tableSortBy != "" && this.options.sortBy) {
                this.options.sortDesc[0] = this.tableSortDescending;
                this.options.sortBy[0] = this.tableSortBy;
              }

              this.contentIsLoading = false;
            } else {
              this.error = res[1].message;
              this.contentIsLoading = false;
            }
          } else {
            this.error = res[0].message;
            this.contentIsLoading = false;
          }
        });
      }
    },
    removeItem(index) {
      Vue.delete(this.content[0].Data, index);
    },
    addObjectToPageContent(properties) {
      var newObject = {
        Title: properties.objectTitle,
        Value: helpers.createEmptyValueBasedOnType(properties.objectType),
        Type: properties.objectType,
        Translate: properties.objectLanguages,
        Options: [],
        Properties: [],
      };

      if (
        properties.objectType == "text" ||
        properties.objectType == "richtext" ||
        properties.objectType == "datepicker" ||
        properties.objectType == "time" ||
        properties.objectType == "dropdown" ||
        properties.objectType == "content"
      ) {
        newObject["Required"] = "false";
      }

      if (
        properties.objectType == "image" ||
        properties.objectType == "croppedimage" ||
        properties.objectType == "gallery"
      ) {
        newObject["AddAltTags"] = "true";
      }

      Vue.set(this.content[0].Data, properties.objectName, newObject);
    },
    addObjectToContentDefinition(properties) {
      var newObject = {
        Title: properties.objectTitle,
        Value: helpers.createEmptyValueBasedOnType(properties.objectType),
        Type: properties.objectType,
        Translate: properties.objectLanguages,
        Options: [],
        Properties: [],
      };

      if (
        properties.objectType == "text" ||
        properties.objectType == "richtext" ||
        properties.objectType == "datepicker" ||
        properties.objectType == "time" ||
        properties.objectType == "dropdown" ||
        properties.objectType == "content"
      ) {
        newObject["Required"] = "false";
      }

      if (
        properties.objectType == "image" ||
        properties.objectType == "croppedimage" ||
        properties.objectType == "gallery"
      ) {
        newObject["AddAltTags"] = "true";
      }

      Vue.set(this.page.ContentDefinition, properties.objectName, newObject);
    },
    removeContentDefinitionItem(index) {
      Vue.delete(this.page.ContentDefinition, index);
    },
    removeOption(index, optionIndex) {
      this.page.ContentDefinition[index].Options.splice(optionIndex, 1);
    },
    addOption(index) {
      this.addOptionFieldHasError = false;
      this.addOptionFieldRule = [];

      if (this.newOptionName.length > 1) {
        this.page.ContentDefinition[index].Options.push({
          Id: this.page.ContentDefinition[index].Options.length + 1,
          Value: this.newOptionName,
        });
      } else {
        this.addOptionFieldRule = [
          () => Vue.i18n.translate("Minimum required characters is") + " 2",
        ];
        this.addOptionFieldHasError = true;
      }
    },
    moveUp(index) {
      var newSort;
      var oldSort = this.content[index].Sort;

      if (index == 0) {
        newSort = this.content[this.content.length - 1].Sort;
      } else {
        newSort = this.content[index - 1].Sort;
      }

      if (newSort == oldSort) {
        newSort += 1;
      }

      var indexToSwapWith = index - 1;

      if (index == 0) {
        indexToSwapWith = this.content.length - 1;
      }

      this.content[index].Sort = newSort;
      this.content[indexToSwapWith].Sort = oldSort;

      this.updateContentSort([
        this.content[index],
        this.content[indexToSwapWith],
      ]);
    },
    moveDown(index) {
      var newSort;
      var oldSort = this.content[index].Sort;

      if (index == this.content.length - 1) {
        newSort = this.content[0].Sort;
      } else {
        newSort = this.content[index + 1].Sort;
      }

      if (newSort == oldSort) {
        newSort -= 1;
      }

      var indexToSwapWith = index + 1;

      if (indexToSwapWith == this.content.length) {
        indexToSwapWith = 0;
      }

      this.content[index].Sort = newSort;
      this.content[indexToSwapWith].Sort = oldSort;

      this.updateContentSort([
        this.content[index],
        this.content[indexToSwapWith],
      ]);
    },
    updateContentSort(items) {
      request.put("/content/updatesort", items, (res) => {
        if (res.success) {
          this.content = this.content.sort(function (a, b) {
            return a.Sort - b.Sort;
          });
        } else {
          this.error = res.message;
        }
      });
    },
    exportPageContent() {
      this.exportDialog = false;

      var fieldsQueryString = "";
      for (var item in this.exportItems) {
        if (this.exportItems[item].selected) {
          fieldsQueryString += "fields=" + this.exportItems[item].value + "&";
        }
        for (var linkedItem in this.exportItems[item].linkedExportItems) {
          if (this.exportItems[item].linkedExportItems[linkedItem].selected) {
            fieldsQueryString +=
              "fields=" +
              this.exportItems[item].value +
              "." +
              this.exportItems[item].linkedExportItems[linkedItem].value +
              "&";
          }
        }
      }

      var queryString =
        "?sortBy=" +
        this.tableSortBy +
        "&sortDescending=" +
        this.tableSortDescending +
        "&search=" +
        (this.searchValue == null ? "" : this.searchValue) +
        this.getPageFiltersQueryString() +
        fieldsQueryString;

      request.download(
        "/exportcontent/" + this.page._id.$oid + queryString,
        { responseType: "blob" },
        "export_ " + this.page._id.$oid + ".xlsx"
      );
    },
    bulkUpdatePageContent() {
      this.bulkUpdatnigContent = true;

      var propertyName = "";
      for (var value in this.page.ContentDefinition) {
        if (
          this.page.ContentDefinition[value].Title ==
          this.selectedBulkUpdateProperty.Title
        ) {
          propertyName = value;
        }
      }

      var queryString =
        "?sortBy=" +
        this.tableSortBy +
        "&sortDescending=" +
        this.tableSortDescending +
        "&search=" +
        (this.searchValue == null ? "" : this.searchValue) +
        this.getPageFiltersQueryString() +
        "&propertyName=" +
        propertyName;

      request.put(
        "/bulkupdatecontent/" + this.page._id.$oid + queryString,
        this.selectedBulkUpdateProperty,
        (res) => {
          if (res.success) {
            if (
              this.page.SynchronizationBulkPutUrl &&
              this.page.SynchronizationBulkPutUrl.length > 0
            ) {
              request.put(
                this.page.SynchronizationBulkPutUrl +
                  "/" +
                  this.page._id.$oid +
                  queryString,
                this.selectedBulkUpdateProperty,
                (res) => {
                  if (!res.success) {
                    this.error = res.message;
                  }
                }
              );
            }
            this.bulkUpdateDialog = false;
            this.bulkUpdatnigContent = false;
            this.$router.go(-1);
          } else {
            this.bulkUpdateDialog = false;
            this.bulkUpdatnigContent = false;
            this.uploadExcelError = res.message;
          }
        }
      );
    },
    getSharedPagesNotAssigned() {
      return this.selectedWebsite.Pages.filter(
        (p) => p.RepeatingContent === true
      );
    },
    customSort: function (items) {
      return items;
    },
    searchContent() {
      var scope = this;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.content = null;
        scope.$refs.infiniteLoading.stateChanger.reset();
        scope.getContent();

        clearTimeout(this.searchTimeout);
      }, 800);
    },
    onSearchCleared() {
      this.searchValue = "";
      this.content = null;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.getContent();
    },
    getBooleanValues() {
      return [
        { label: Vue.i18n.translate("Yes"), value: true },
        { label: Vue.i18n.translate("No"), value: false },
      ];
    },
    searchContentFilter(linkedPage, index) {
      if (this.searchingContentFilters[index]) return;
      if (!linkedPage) return;

      this.searchingContentFilters[index] = true;

      request.get(
        "/pagecontentsearch/" +
          linkedPage.$oid +
          "?searchValue=" +
          this.searchContentFilterModels[index],
        null,
        (res) => {
          if (res.success) {
            this.contentFilterSearchResults[index] = res.data;
          } else {
            this.error = res.message;
          }

          this.searchingContentFilters[index] = false;
        }
      );
    },
    filterChanged(value, filterOn) {
      this.pageFilters[filterOn] = value;
      this.content = null;
      this.getContent();
    },
    getPageFiltersQueryString() {
      var filters = "";
      for (var filter in this.pageFilters) {
        if (
          this.pageFilters[filter] != null &&
          this.pageFilters[filter] != undefined
        ) {
          if (Array.isArray(this.pageFilters[filter])) {
            for (var currentFilterIndex in this.pageFilters[filter]) {
              filters +=
                "filters=" +
                filter +
                ":" +
                this.pageFilters[filter][currentFilterIndex]._id.$oid +
                "&";
            }
          } else if (this.pageFilters[filter].$oid) {
            filters +=
              "filters=" + filter + ":" + this.pageFilters[filter].$oid + "&";
          } else {
            filters +=
              "filters=" + filter + ":" + this.pageFilters[filter] + "&";
          }
        }
      }
      return "&" + filters;
    },
    getExportItemsFromPage(page, isLinkedPageItem) {
      if (page.RepeatingContent) {
        var items = [];
        for (var value in page.ContentDefinition) {
          if (
            page.ContentDefinition[value].Type != "image" &&
            page.ContentDefinition[value].Type != "croppedimage" &&
            page.ContentDefinition[value].Type != "gallery" &&
            page.ContentDefinition[value].Type != "files" &&
            (page.ContentDefinition[value].Type != "content" ||
              !isLinkedPageItem)
          ) {
            items.push({
              title: page.ContentDefinition[value].Title,
              value: value,
              selected: false,
            });
          }
        }
        return items;
      }
      return [];
    },
  },
};
</script>

<style>
hr {
  border-top: 1px solid #bbb;
}

#editPageForm .textInput.error,
#editPageForm .editr--content.error {
  background-color: transparent !important;
}

#editPageForm .editr--content.error {
  border-width: 1px;
  border-style: solid;
}

.sort-button {
  margin-top: 5px;
}
.picker .v-input__control .v-input__slot .v-text-field__slot {
  height: 41.7px !important;
}
.picker .v-input__control .v-input__slot .v-text-field__slot input {
  margin-top: 0.85rem !important;
}
.property-filter {
  max-width: 200px;
  float: left;
  margin-right: 1rem;
}

.filters-row .v-select__slot {
  min-height: 42px;
}

@media only screen and (max-width: 600px) {
  .item-type {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 495px) {
  .sort-button {
    margin-top: -3px;
  }
}
</style>

export default {
  hideMessageTimeout: 2000,
  objectTypes: ["text","richtext","image","croppedimage","gallery","datepicker","dropdown", "boolean", "files", "content", "number", "time"],
  contentLanguages: ["Nl","Fr","En"],
  languages: ["Nl","Fr", "En"],
  logoPos: "elektrobossuyt_logo_pos.png",
  logoNeg: "elektrobossuyt_logo.png",
  websiteName: "Elektro Bossuyt - Loyalty program",
  primaryColor: "#005870",
  secondaryColor: "#439f35",
  mailto: "office@elektrobossuyt.be",
  apiUrl: "https://loyalty-program.elektrobossuyt.be",
}
